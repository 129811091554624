import revive_payload_client_4sVQNw7RlN from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/workspace/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/workspace/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workspace/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_m9SjywKgWm from "/workspace/frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/workspace/frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/workspace/frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/workspace/frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_ybZ5mczHdl from "/workspace/frontend/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_8SbxDRbG6Y from "/workspace/frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_nuxt3_gGdllsjxuZ from "/workspace/frontend/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/workspace/frontend/plugins/Vue3Lottie.client.ts";
import chartjs_1wC9lAM0Tp from "/workspace/frontend/plugins/chartjs.ts";
import debounce_4Kl87r7kXF from "/workspace/frontend/plugins/debounce.ts";
import sentry_3AyO8nEfhE from "/workspace/frontend/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  plugin_ybZ5mczHdl,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  plugin_nuxt3_gGdllsjxuZ,
  Vue3Lottie_client_bMZiOS6AB0,
  chartjs_1wC9lAM0Tp,
  debounce_4Kl87r7kXF,
  sentry_3AyO8nEfhE
]