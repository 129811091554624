import { default as icons_45pageazEIgN5laEMeta } from "/workspace/frontend/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as indexUziJifzQ4yMeta } from "/workspace/frontend/pages/index.vue?macro=true";
import { default as manusUX08OvbF9eMeta } from "/workspace/frontend/pages/manus.vue?macro=true";
import { default as adminxCoVTGeC5hMeta } from "/workspace/frontend/pages/rom/[code]/admin.vue?macro=true";
import { default as indexOgu2ac4hUdMeta } from "/workspace/frontend/pages/rom/[code]/index.vue?macro=true";
import { default as index7V8cny1k0dMeta } from "/workspace/frontend/pages/rom/index.vue?macro=true";
import { default as startw0HyMkn9t8Meta } from "/workspace/frontend/pages/start.vue?macro=true";
import { default as component_45stub7VUunYBGJAMeta } from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub7VUunYBGJA } from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: icons_45pageazEIgN5laEMeta?.name ?? "icons-page",
    path: icons_45pageazEIgN5laEMeta?.path ?? "/_icons",
    meta: { ...(icons_45pageazEIgN5laEMeta || {}), ...{"layout":"svg-sprite"} },
    alias: icons_45pageazEIgN5laEMeta?.alias || [],
    redirect: icons_45pageazEIgN5laEMeta?.redirect,
    component: () => import("/workspace/frontend/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: indexUziJifzQ4yMeta?.name ?? "index",
    path: indexUziJifzQ4yMeta?.path ?? "/",
    meta: indexUziJifzQ4yMeta || {},
    alias: indexUziJifzQ4yMeta?.alias || [],
    redirect: indexUziJifzQ4yMeta?.redirect,
    component: () => import("/workspace/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: manusUX08OvbF9eMeta?.name ?? "manus",
    path: manusUX08OvbF9eMeta?.path ?? "/manus",
    meta: manusUX08OvbF9eMeta || {},
    alias: manusUX08OvbF9eMeta?.alias || [],
    redirect: manusUX08OvbF9eMeta?.redirect,
    component: () => import("/workspace/frontend/pages/manus.vue").then(m => m.default || m)
  },
  {
    name: adminxCoVTGeC5hMeta?.name ?? "rom-code-admin",
    path: adminxCoVTGeC5hMeta?.path ?? "/rom/:code()/admin",
    meta: adminxCoVTGeC5hMeta || {},
    alias: adminxCoVTGeC5hMeta?.alias || [],
    redirect: adminxCoVTGeC5hMeta?.redirect,
    component: () => import("/workspace/frontend/pages/rom/[code]/admin.vue").then(m => m.default || m)
  },
  {
    name: indexOgu2ac4hUdMeta?.name ?? "rom-code",
    path: indexOgu2ac4hUdMeta?.path ?? "/rom/:code()",
    meta: indexOgu2ac4hUdMeta || {},
    alias: indexOgu2ac4hUdMeta?.alias || [],
    redirect: indexOgu2ac4hUdMeta?.redirect,
    component: () => import("/workspace/frontend/pages/rom/[code]/index.vue").then(m => m.default || m)
  },
  {
    name: index7V8cny1k0dMeta?.name ?? "rom",
    path: index7V8cny1k0dMeta?.path ?? "/rom",
    meta: index7V8cny1k0dMeta || {},
    alias: index7V8cny1k0dMeta?.alias || [],
    redirect: index7V8cny1k0dMeta?.redirect,
    component: () => import("/workspace/frontend/pages/rom/index.vue").then(m => m.default || m)
  },
  {
    name: startw0HyMkn9t8Meta?.name ?? "start",
    path: startw0HyMkn9t8Meta?.path ?? "/start",
    meta: startw0HyMkn9t8Meta || {},
    alias: startw0HyMkn9t8Meta?.alias || [],
    redirect: startw0HyMkn9t8Meta?.redirect,
    component: () => import("/workspace/frontend/pages/start.vue").then(m => m.default || m)
  },
  {
    name: component_45stub7VUunYBGJAMeta?.name ?? undefined,
    path: component_45stub7VUunYBGJAMeta?.path ?? "/auth/login",
    meta: component_45stub7VUunYBGJAMeta || {},
    alias: component_45stub7VUunYBGJAMeta?.alias || [],
    redirect: component_45stub7VUunYBGJAMeta?.redirect,
    component: component_45stub7VUunYBGJA
  },
  {
    name: component_45stub7VUunYBGJAMeta?.name ?? undefined,
    path: component_45stub7VUunYBGJAMeta?.path ?? "/auth/logout",
    meta: component_45stub7VUunYBGJAMeta || {},
    alias: component_45stub7VUunYBGJAMeta?.alias || [],
    redirect: component_45stub7VUunYBGJAMeta?.redirect,
    component: component_45stub7VUunYBGJA
  }
]